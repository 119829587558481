<template>
  <div class="investBank">
    <img-div
      class-name=""
      height="400"
      :img-url="require('@/assets/business/investBank/topBanner.png')"
    >
      <div class="container">
        <div class="p1 mt123">
          {{ $t("business.investBank.topBannerTitle") }}
        </div>
        <div class="desc1 mt26">
          {{ $t("business.investBank.topBannerDesc1") }}
        </div>
      </div>
    </img-div>
    <div class="container product">
      <div class="itemTitle">
        {{ $t("business.investBank.productTitle") }}
      </div>
      <div class="flex flex-jsb flex-hw mt33">
        <div
          class="product-item flex flex-vc"
          v-for="(item, index) in productList"
          :key="index"
        >
          <el-image
            :src="item.imgUrl"
            fit="contain"
            class="product-icon"
          ></el-image>
          <div class="">
            <div class="listItemTitle">{{ item.title }}</div>
            <div class="productDesc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="teamProduct">
      <div class="itemTitle">
        {{ $t("business.investBank.teamProductTitle") }}
      </div>
      <div class="container desc1">
        {{ $t("business.investBank.teamProductDesc") }}
      </div>
    </div>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
export default {
  components: {
    ImgDiv,
  },
  data() {
    return {};
  },
  computed: {
    productList() {
      return this.$t("business.investBank.productList") || [];
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.investBank {
  background-color: var(--whiteColor);
  .about-url {
    padding: 50px 258px;
  }
  .product-icon {
    width: 280px;
    flex-shrink: 0;
    margin-right: 24px;
  }
  .a-right {
    width: 14px;
  }
  .product {
    padding-bottom: 100px;
    .product-item {
      background: var(--whiteColor);
      border-radius: 6px;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.13);
      width: 560px;
      padding-right: 25px;
      .listItemTitle {
        margin-top: 0;
      }
      .productDesc {
        color: var(--blackColor);
        font-size: 14px;
        margin-top: 22px;
        line-height: 22px;
        text-indent: 28px;
      }
    }
  }
  .teamProduct {
    background-color: var(--bgGray);
    padding: 90px 0;
    .itemTitle {
      margin-top: 0;
    }
    .container {
      margin-top: 28px;
      line-height: 24px;
    }
    .desc1 {
      color: var(--blackColor);
    }
  }
}
</style>
